import React, { ReactNode } from 'react';
import { ReadonlyValue } from '@primitives';
import { FormRow } from './FormRow';

type Value = string | number | ReactNode;

interface ReadonlyFormRowProps {
  id: string;
  label?: string;
  value?: Value;
  values?: any;
  mt?: string;
}

const ReadonlyFormRow: React.FC<ReadonlyFormRowProps> = ({
  id, label, value, values, ...rest
}) => {
  const visibleValue = values ? values[id] : value;
  return (
    <FormRow
      label={label}
      id={id}
      component={<ReadonlyValue id={id}>{visibleValue}</ReadonlyValue>}
      {...rest}
    />
  );
};

export { ReadonlyFormRow };

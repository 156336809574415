/* eslint-disable react/no-danger */
import React, { FC, useState, useEffect } from 'react';
import { ContentPageLayout } from '@components/Layout';
import { Form, ReadOnlyForm } from '@components/Form';
import {
  Heading, TwoColorButton, Box, Button,
} from '@primitives';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { navigate } from 'gatsby';
import { MOVE_IN_REPORT } from '@constants';
import { useStores } from '@stores';
import { IResident, IMarkdownData, IAgreementStore, ICommonDataStore } from '../../types';
import { observer } from 'mobx-react';
import { v4 as uuid } from 'uuid';
import { ReadonlyFormRow } from '@components/FormRow';
import styled from 'styled-components';


interface IMoveInReportFormPage {
  data: IMarkdownData;
}

const ResidentRow = styled(Box) <{ newResident?: boolean }>`
  display: flex;
  > * {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const MoveInReportFormPage: FC<IMoveInReportFormPage> = observer(({
}) => {
  const {
    agreementStore: {
      selectedAgreement, selectedAgreementId, residents: allResidents, getResidents,
    },
    commonDataStore: { sendMoveInReport },
  }: {
    agreementStore: IAgreementStore;
    commonDataStore: ICommonDataStore;
  } = useStores();

  const INITIAL_DOOR_NAMES = {
    door_name1: '',
    door_name2: '',
  }
  const [residentDraft, setResidentDraft] = useState(null);
  const [residentDraftOpen, setResidentDraftOpen] = useState(false);
  const [unsavedResidents, setUnsavedResidents] = useState([] as any);
  const [doorNames, setDoorNames] = useState(INITIAL_DOOR_NAMES);
  const residents = allResidents.find((resObj) => resObj.agreement_id === selectedAgreementId)?.residents || [];

  useEffect(() => {
    if (selectedAgreementId && !allResidents.find((resObj) => resObj.agreement_id === selectedAgreementId)) getResidents(selectedAgreementId);
  }, [allResidents, getResidents, selectedAgreementId]);


  const onSubmit = async (e: MouseEvent) => {
    e.preventDefault();

    const obj = {
      ...doorNames,
      moving_in_date: selectedAgreement?.start_date || selectedAgreement?.occupancy_start_date,
      residents: unsavedResidents
    };

    try {
      await sendMoveInReport(obj);
      setDoorNames(INITIAL_DOOR_NAMES);
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error) {
      console.error(error)
    }

  };

  const onAddResidentClick = () => {
    setResidentDraftOpen(true);
  };

  const onCancelResidentAdd = () => {
    setResidentDraft(null);
    setResidentDraftOpen(false);
  };

  const getTranslation = (id: string) => <M id={`fields.label.moveInReport.${id}`} />;

  const onSubmitDraftClick = (newResident: any) => {
    setUnsavedResidents([...unsavedResidents, ...[{ ...newResident, ...{ id: uuid() } }]]);
    setResidentDraft(null);
    setResidentDraftOpen(false);
  };

  const onRemoveDraftClick = (resident: any) => {
    setUnsavedResidents(unsavedResidents.filter((savedResident: any) => savedResident.id !== resident.id));

  };

  const renderReadOnlyResident = (resident: IResident) => {
    return (
      <ResidentRow mb="1" newResident={!resident.party_id}>
        <ReadonlyFormRow id={`${resident.party_id}-firstName`} label="firstName" value={resident.first_name} />
        <ReadonlyFormRow id={`${resident.party_id}-lastName`} label="lastName" value={resident.last_name} />
        {!resident.party_id && <Button onClick={() => onRemoveDraftClick(resident)}><M id="fields.label.remove" /></Button>}
      </ResidentRow>
    );
  }
  const onDoorNameChange = ({ values }: { values: any }) => {
    setDoorNames(values);
  };

  const isSubmitDisabled = (!doorNames.door_name1 && !doorNames.door_name2) || residentDraftOpen;

  const agreement = { ...{}, ...selectedAgreement } as any;
  agreement.moveInDate = selectedAgreement?.occupancy_start_date || selectedAgreement?.start_date;

  return (
    <ContentPageLayout>
      <Heading center mb="3" level="1">{getTranslation('moveInReport')}</Heading>
      <Box mb="3">
        <Heading mt="2" mb="2" level="3">{getTranslation('addressMoveInDate')}</Heading>
        <ReadOnlyForm
          id="general"
          values={agreement}
          fields={MOVE_IN_REPORT.general}
        />
      </Box>

      <Box mb="3">
        <Heading mb="3" level="3">{getTranslation('residentInformation')}</Heading>
        {residents?.map((resident, _index) => renderReadOnlyResident(resident))}

        {!!unsavedResidents.length && (
          <Box>
            {residents?.length && <Heading mt="3" mb="3" level="3">{getTranslation('newResidents')}</Heading>}
            {unsavedResidents.map((resident: IResident) => renderReadOnlyResident(resident))}
          </Box>
        )}
      </Box>

      {residentDraftOpen && (
        <Box mb="2">
          <Heading mt="2" mb="2" level="4">{getTranslation('typeResidentInformation')}</Heading>
          <Box>
            <Form
              fields={MOVE_IN_REPORT.newResident}
              values={residentDraft}
              id="new-resident"
              styles={['labelOnTop']}
              onSubmit={onSubmitDraftClick}
              submitText="fields.label.moveInReport.addResident"
              footer={<Button onClick={onCancelResidentAdd} ml="1" id="cancel"><M id="fields.label.cancel" /></Button>}
            />
          </Box>
        </Box>
      )}

      {!residentDraftOpen && (
        <TwoColorButton
          mt="1"
          symbol="+"
          title="fields.label.moveInReport.addResidentLong"
          onClick={onAddResidentClick}
          id="add-new-resident" />
      )}

      <Box mb="2">
        <Heading mt="3" mb="2" level="3">{getTranslation('lastNames')}</Heading>
        <Form
          fields={MOVE_IN_REPORT.doorNames}
          values={doorNames}
          id="door-names"
          styles={['labelOnTop']}
          onChange={onDoorNameChange}
        />
      </Box>
      <Button id="submit" disabled={isSubmitDisabled} onClick={onSubmit}><M id="fields.label.send" /></Button>
    </ContentPageLayout>
  );
});

export default MoveInReportFormPage;

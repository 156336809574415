import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Label } from '@primitives';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { displayUntil, until } from '../../styles/theme-utils';
import { rem } from 'polished';

export interface FormRowProps {
  label?: string;
  id: string;
  component: ReactNode | string;
  required?: boolean;
}

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  ${displayUntil('lg', 'block')};
`;

const RequiredIndicator = styled.span`
  margin-left: 0.25rem;
`;

const FieldLabel = styled(Label)`
  flex: 1 2 100%;
  display: block;
  ${until('lg', 'padding-bottom: 1rem')};
  max-width: ${rem('150px')};
  margin-right: 1rem;
`;

const FieldContainer = styled.div`
  flex: 2 1 100%;
  display: flex;
  flex-wrap: wrap;
`;

const FormRow: React.FC<FormRowProps> = ({
  label, id, component, required, ...rest
}) => (
  <Row {...rest}>
    <FieldLabel htmlFor={`form-control-${id}`}>
      <M id={`fields.label.${label || id}`} />
      {required && <RequiredIndicator>*</RequiredIndicator>}
    </FieldLabel>
    <FieldContainer>
      {component}
    </FieldContainer>
  </Row>
);

export { FormRow };
